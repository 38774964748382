import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'


const items = [
    { src:require("../images/interiors/lux/img--LUX-living-1.jpg").default, caption:"LIVING", x:632, y:200, width:584, height:247 },
    { src:require("../images/interiors/lux/img--LUX-dining-1.jpg").default, caption:"TERRACE", x:1236, y:200, width:584, height:247 },
    { src:require("../images/interiors/lux/img--LUX-terrace-1.jpg").default, caption:"TERRACE & LIVING", x:632, y:466, width:384, height:247 },
    { src:require("../images/interiors/lux/img--LUX-kitchen-1.jpg").default, caption:"KITCHEN", x:1034, y:466, width:384, height:247 },
    { src:require("../images/interiors/lux/img--LUX-kitchen-2.jpg").default, caption:"KITCHEN", x:1436, y:466, width:384, height:247 },
    { src:require("../images/interiors/lux/img--LUX-terrace-2.jpg").default, caption:"TERRACE", x:632, y:734, width:584, height:247 },
    { src:require("../images/interiors/lux/img--LUX-flexi-1.jpg").default, caption:"LIVING", x:1236, y:734, width:584, height:247 },
    
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items} debug={false}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/interiors/lux/bkg--LUX-interiors-1.jpg").default} />
        </div>
    )
}