import * as React from 'react'

type Props = {
    retainFirst?:boolean,
    children:React.ReactNode
}


export default function({children, retainFirst}:Props){
    const [selected, setSelected] = React.useState(0);
    
    React.useEffect(() => {
        const timer = setTimeout(function(){
           setSelected(selected => selected+1);
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    }, [selected])

    return (
        <div className="fade-between">
            {
                React.Children.map(children, (child, index) => (
                    <div data-active={(retainFirst&&index==0) ||  selected%React.Children.count(children) == index }>{child}</div>
                ))
            }
        </div>
    )
}
