import Dots from '../components/Dots'
import FadeBetween from '../components/FadeBetween'
import ImageLoader from '../components/ImageLoader'


export default function(){
    return (
        <FadeBetween retainFirst={true}>
            <div>
                <Dots num={2} selected={0} />
                <ImageLoader src={require("../images/location/bkg--location-1.jpg").default} />
            </div>
            <div>
                <ImageLoader src={require("../images/location/SEH004-LUX-Lifestyle-page-2.jpg").default} />
                <Dots num={2} selected={1} />
            </div>
        </FadeBetween>
    )
}