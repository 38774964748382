import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { DumbLoadingIndicator as LoadingIndicator } from "./LoadingIndicator";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SubmitButton from "./SubmitButton";
import useOutsideClick from "@rooks/use-outside-click";
import { SalesData } from "../data/sales-data";
import CurrencyFormatter from 'currency-formatter'

var FileSaver = require('file-saver');


type Props = {
    id:string|null,
    pdf:string|null,
    onClose:()=>void,
    salesData:SalesData,
    apartmentId:string|null,
}


export default function({pdf, id, apartmentId, salesData, onClose}:Props){
    const ref = useRef<HTMLImageElement>(null);
    const planRef:MutableRefObject<HTMLDivElement> = useRef() as MutableRefObject<HTMLDivElement>;

    
    const [state, setState] = useState({
        src:null as null|string,
        visible:false,
        loading: false,
        apartmentId:null as null|string,
    });


    const onload = useCallback(() => {
        //console.log("Load complete")
        //setTimeout(function(){
        setState({
            ...state,
            loading: false,
        })
        //}, 1000);
    }, [ref.current, state.loading])

    useEffect(() => {
        //console.log("URL Set")
        setState({
            ...state,
            loading: (id && state.src!=id) ? true : state.loading,
            src: `/plans/${id}.jpg` || state.src,
            apartmentId: apartmentId || state.apartmentId,
            visible: !!id,
        })
    }, [id, apartmentId]);

    useEffect(() => {
        //console.log("ReF set")
        if(ref.current){
            setState({
                ...state,
                loading: !ref.current.complete
            })
        }
    }, [ref.current])


    useEffect(() => {
        if(state.loading && ref.current){
          //  console.log("Set c")
            ref.current.onload = onload;
        }
    }, [ref.current, state.loading])


    function onDownload(){
        console.log("On Download")
        if(pdf){
            window.open(`pdfs/${pdf}`, "_blank");
            //downloadFile(pdf);
        }else{
            alert("PDF Could not be downloaded.")
        }
    }
    
    const price = state.apartmentId && CurrencyFormatter.format(parseInt(salesData[state.apartmentId].price) || 0, {
        symbol: '$',
        decimal: '.',
        thousand: ',',
        precision: 0,
        format: '%s%v' // %s is the symbol and %v is the value
      });

    return (
        <div ref={planRef} className="Plan" data-loading={state.loading} data-visible={state.visible}>
            { state.src && 
                <LoadingIndicator loading={state.loading}>
                    <TransformWrapper>
                        {({ scale, zoomIn, zoomOut, resetTransform }:any) => (
                            <div>
                                <Resetter visible={state.visible} resetTransform={resetTransform}>
                                    <img className="PlanImage" ref={ref} src={state.src!} alt="" width="1470px" height=" 980px"/>
                                </Resetter>
                                <div className="PlanUI" data-zoomed={scale!=1}>
                                    <div className="PlanUITop">
                                        <div className="PlanTitle">APARTMENT {state.apartmentId}</div>
                                        <div className="PlanPrice">{price}</div>
                                        <SubmitButton title="DOWNLOAD" onClick={onDownload} />
                                    </div>
                                    <div onClick={zoomIn} className="PlanZoomIn"><img src={require('../images/interface/icon--zoom-in.png').default} alt="Zoom in" /></div>
                                    <div onClick={zoomOut} className="PlanZoomOut"><img src={require('../images/interface/icon--zoom-out.png').default} alt="Zoom out" /></div>
                                </div>
                            </div>
                        )}
                    </TransformWrapper>
                    
                </LoadingIndicator>
            }
            <div className="PlanClose" onClick={onClose}><img src={require('../images/interface/btn--close-navy.png').default} /></div>
        </div>
    )
}


function downloadFile(fileName:string) {
    FileSaver.saveAs(`/pdfs/${fileName}`, fileName);
}

function Resetter({visible, resetTransform, children}:{visible:boolean, resetTransform:()=>void, children:React.ReactNode}){
    const [changes, setChanges] = useState(0);

    useEffect(() => {
        if(changes>0 && !visible){
            resetTransform();
        }
    }, [changes, visible]);

    useEffect(() => {
        setChanges(changes+1);
    }, [visible])

    return (
        <TransformComponent>
            { children }
        </TransformComponent>
    )
}