import { useContext, useEffect, useState } from 'react'
import Field from '../components/Field'
import Form from '../components/Form'
import Button from '../components/Button'
import Background from '../components/Background'
import ImageLoader from '../components/ImageLoader'
import Rules from '../helper/rules'
import SubmitButton from '../components/SubmitButton'
import { apiFetch } from '../helper/api'
import fields from '../core/fields'
import { Context } from '../store/store'


const rules = {
    firstName: [
        Rules.enforce.required("First name is required.")
    ],
    lastName: [
        Rules.enforce.required("Last name is required.")
    ],
    phone: [
        Rules.enforce.required("Phone number is required.")
    ],
    email: [
        Rules.enforce.required("Email is required."),
        Rules.enforce.email("Please enter a valid email.")
    ],
}


const defaultFields = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
}


export default function(){
    const [state, setState] = useState({
        fields: defaultFields,
        errors: null as null | Record<keyof typeof defaultFields, string[]>,
        submissions: 0,
    })

    const store = useContext(Context);


    useEffect(function(){
        async function doRequest(){
            if(state.submissions > 0){
                const response = await apiFetch('https://cms.orchardslumia.com.au/api/app-enquire.php', {
                    first_name: state.fields.firstName,
                    last_name: state.fields.lastName,
                    phone: state.fields.phone,
                    email: state.fields.email,
                    user_name: store.state.user.username,
                    password: store.state.user.password,
                })

                if(response.ok){
                    setState({
                        ...state,
                        fields: defaultFields,
                    })
                    alert("Enquiry was submitted.")
                }else{
                    alert("An error has occured.")
                }
            }
        }
        doRequest();
    }, [state.submissions])
    
    
    function setField(name:keyof typeof defaultFields, value:string){
        setState({
            ...state,
            fields:{
                ...state.fields,
                [name]: value,
            }
        })
    }


    function setErrors(errors:typeof state['errors']){
        setState({
            ...state,
            errors
        })
    }


    function onSubmitPressed(){
        console.log("Submit");
        const errors = Rules.getErrors(state.fields, rules);
        if(errors){
            setErrors(errors);
        }else{
            setState({
                ...state,
                errors:null,
                submissions: state.submissions+1,
            })
        }
    }


    return (
        <>
            <Background>
                <ImageLoader src={require('../images/enquire/bkg--enquire.png').default} />
            </Background>
            <Form>
                <Field name="firstName" placeholder="First Name" value={state.fields.firstName} errors={state.errors?.firstName} type="text" onChange={setField} />
                <Field name="lastName"  placeholder="Last Name"  value={state.fields.lastName}  errors={state.errors?.lastName}  type="text" onChange={setField} />
                <Field name="phone"     placeholder="Phone"      value={state.fields.phone}     errors={state.errors?.phone}     type="tel" onChange={setField} />
                <Field name="email"     placeholder="E-mail"     value={state.fields.email}     errors={state.errors?.email}     type="email" onChange={setField} />
                <SubmitButton onClick={onSubmitPressed}/>
            </Form>
        </>
    )
}

