import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'


const items = [
    { src:require("../images/design/lumia-lux/exterior-assets/img--creek-1.jpg").default, caption:"CREEK", x:630, y:200, width:376, height:470 },
    { src:require("../images/design/lumia-lux/exterior-assets/img--elevation-1.jpg").default, caption:"ELEVATION", x:1020, y:200, width:800, height:470 },
    { src:require("../images/design/lumia-lux/exterior-assets/img--pool-1.jpg").default, caption:"POOL", x:630, y:690, width:376, height:290 },
    { src:require("../images/design/lumia-lux/exterior-assets/img--boardwalk-1.jpg").default, caption:"BOARDWALK", x:1020, y:690, width:406, height:290 },
    { src:require("../images/design/lumia-lux/exterior-assets/img--pool-2.jpg").default, caption:"POOL", x:1440, y:690, width:380, height:290 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items} debug={false}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/design/lumia-lux/SEH004-LUX-Exterior-layout.jpg").default} />
        </div>
    )
}

