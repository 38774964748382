import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'


const items = [
    { src:require("../images/design/img--hero.jpg").default, caption:"EXTERIOR", x:710, y:200, width:802, height:393 },
    { src:require("../images/design/img--exterior-corner.jpg").default, caption:"EXTERIOR", x:1110, y:613, width:401, height:368 },
    { src:require("../images/design/img--exterior-facade.jpg").default, caption:"EXTERIOR", x:1534, y:200, width:286, height:248 },
    { src:require("../images/design/img--park.jpg").default, caption:"EXTERIOR", x:1534, y:468, width:286, height:248 },
    { src:require("../images/design/img--pool.jpg").default, caption:"LAGOON POOL", x:710, y:613, width:377, height:368 },
    { src:require("../images/design/img--pool-2.jpg").default, caption:"LAGOON POOL", x:1534, y:736, width:286, height:245 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/design/bkg--design-1.jpg").default} />
        </div>
    )
}