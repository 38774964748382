import * as React from 'react'
import { useKeyPress } from "@react-typed-hooks/use-key-press";


export default function(){
    const isMPressed = useKeyPress({ targetKey: "m"})
    const isControlPressed = useKeyPress({ targetKey: "Control"})
    const isKeyCombinationPressed = isMPressed && isControlPressed;

    const [visible, setVisible] = React.useState(isKeyCombinationPressed);

    React.useEffect(function(){
        setVisible(isKeyCombinationPressed);
    }, [isKeyCombinationPressed]);

    if(!visible) return null;

    return (
        <div className="Mockup">
            <img src={require("../images/mockup/home.png").default} />
        </div>
    )
}
